import React, { useState } from 'react';

import {
  Input,
  TextArea,
  maskPhoneNumber,
  validateEmail,
} from '@nerds-with-charisma/nerds-style-sass';

const ResumeUploadForm = () => {
  const [isInvalid, isInvalidSetter] = useState(true);

  const [name, nameSetter] = useState(null);
  const [nameError, nameErrorSetter] = useState(null);
  const [email, emailSetter] = useState(null);
  const [emailError, emailErrorSetter] = useState(null);
  const [phone, phoneSetter] = useState(null);
  const [message, messageSetter] = useState(null);
  const [messageError, messageErrorSetter] = useState(null);

  const handleValidation = () => {
    let hasErrors = true;

    if (!name || name.length < 2) {
      nameErrorSetter('Enter a valid name');
      hasErrors = true;
    } else {
      nameErrorSetter(null);
      hasErrors = false;
    }

    if (!email || email.length < 2 || validateEmail(email).error === true) {
      emailErrorSetter('Enter a valid email');
      hasErrors = true;
    } else {
      emailErrorSetter(null);
      hasErrors = false;
    }

    if (!message || message.length < 2) {
      messageErrorSetter('Enter a valid message');
      hasErrors = true;
    } else {
      messageErrorSetter(null);
      hasErrors = false;
    }

    if (hasErrors === false) {
      isInvalidSetter(false);
    } else {
      isInvalidSetter(true);
    }
  };

  return (
    <form
      id="resumeUploadForm"
      name="resumeUploadForm"
      data-netlify="true"
      method="post"
    >
      <strong>
        {'Contact Us'}
      </strong>
      <br />
      <br />
      <Input
        title="Name"
        id="input--name"
        callback={(v) => nameSetter(v)}
        error={nameError}
        type="text"
        required
        populatedValue={name}
        blurCallback={handleValidation}
      />

      <br />
      <br />

      <input type="hidden" name="form-name" value="resumeUploadForm" />

      <Input
        title="Email"
        id="input--email"
        callback={(v) => emailSetter(v)}
        error={emailError}
        type="email"
        required
        populatedValue={email}
        blurCallback={handleValidation}
      />

      <br />
      <br />

      <Input
        title="Phone"
        id="input--maskedPhone"
        callback={(v) => {
          phoneSetter(maskPhoneNumber(v));
          document.getElementById('input--maskedPhone').value = maskPhoneNumber(v);
        }}
        type="tel"
        placeHolder="(555) 555-5555"
        maxLength="14"
        minLength="14"
        populatedValue={phone}
        blurCallback={handleValidation}
      />

      <br />
      <br />

      <TextArea
        title="Message"
        id="input--message"
        callback={(v) => messageSetter(v)}
        error={messageError}
        required
        populatedValue={message}
        rows="3"
        blurCallback={handleValidation}
      />

      <br />
      <br />

      <label id="resumeUploadLabel" htmlFor="resumeUpload" className="border block radius--sm font--14">
        <div className="nwc--label-wrap">
          <strong className="font--14">
            {'Upload Resume'}
            <sup className="font--error font--10">
              {' *'}
            </sup>
          </strong>
        </div>

        <input
          style={{ width: '100%' }}
          className="block padding--lg"
          type="file"
          name="resumeUpload"
          id="resumeUpload"
          title="Upload Resume"
          onChange={() => {
            // if too big, remove it and alert them
            if (document.getElementById('resumeUpload').files[0].size > 1000000) {
              alert('Please keep your files under 1MB');
              document.getElementById('resumeUpload').value = '';
            }
          }}
        />
      </label>

      <br />
      <br />

      <button
        type="submit"
        disabled={isInvalid}
        className="btn bg--upendoPrimary float--right radius--lg border--none font--16"
      >
        <strong>
          {'Submit Your Resume'}
        </strong>
      </button>
    </form>
  );
};

export default ResumeUploadForm;
