import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import InnerHero from '../components/InnerHero';
import ResumeUploadForm from '../components/ResumeUploadForm';

const Seekers = () => (
  <StaticQuery
    query={graphql`
      query SeekersQuery {
        allContentfulSeekers {
          edges {
            node {

              hero {
                file {
                  url
                }
              }

              tagline

              upperContent {
                content {
                  content {
                    value
                  }
                }
              }

              upperImage {
                file {
                  url
                }
              }

              middleTagline

              middleContent {
                content {
                  content {
                    value
                  }
                }
              }

              middleImage {
                file {
                  url
                }
              }

              bottomContent {
                content {
                  content {
                    value
                  }
                }
              }

              pageTitle

              meta {
                meta {
                  name
                  content
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const graphqlData = data.allContentfulSeekers.edges[0].node;

      return (
        <Layout>
          <SEO
            title={graphqlData.pageTitle}
            lang="en"
            meta={graphqlData.meta.meta}
          />

          <InnerHero image={graphqlData.hero.file.url} tagline={graphqlData.tagline} showButtons />

          <div className="container--md">
            <section id="upper" className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <img src={graphqlData.upperImage.file.url} alt="South Florida Talent Agency, find the best positions through Upendo Staffing" />
              </div>
              <div className="col-12 col-sm-12 col-md-1 col-lg-1" />
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 align--center">
                <div dangerouslySetInnerHTML={{
                  __html: graphqlData.upperContent.content[0].content[0].value,
                }}
                />
              </div>
            </section>

            <br />
          </div>
          <div className="bg--upendoLight">
            <div className="container--md">
              <section id="middle" className="row padding--md">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 padding--lg font--26 text--center">
                  <strong>
                    {graphqlData.middleTagline}
                  </strong>
                </div>
                <div id="middle" className="col-12 col-sm-12 col-md-6 col-lg-6 align--center">
                  <div dangerouslySetInnerHTML={{
                    __html: graphqlData.middleContent.content[0].content[0].value,
                  }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <img src={graphqlData.middleImage.file.url} alt="Personalized Candidates" />
                </div>
              </section>
            </div>
            <br />
          </div>

          <div className="container--md">
            <br />
            <section id="lower" className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 text--center font--26 align--center">
                <strong>
                  {graphqlData.bottomTagline}
                </strong>
                <br />
                <br />
              </div>

              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <ResumeUploadForm />
              </div>

              <div className="col-1" />

              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div dangerouslySetInnerHTML={{
                  __html: graphqlData.bottomContent.content[0].content[0].value,
                }}
                />
                <br />

                <iframe title="seekersMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114018.30842151608!2d-80.19925129280001!3d26.74207396774542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d8d5ccb595adc1%3A0x15efc7b51fe16bde!2sWest%20Palm%20Beach%2C%20FL!5e0!3m2!1sen!2sus!4v1578071704428!5m2!1sen!2sus" width="100%" height="250" frameBorder="0" />
              </div>
            </section>
          </div>

          <br />
          <br />
        </Layout>
      );
    }}
  />
);

export default Seekers;
